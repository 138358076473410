<template>
  <div class="reset-success">
    <h1>비밀번호 설정 완료</h1>
    <h2>
      비밀번호 설정이 완료되었습니다.
      <br />
      등록하신 비밀번호로 로그인 후 사용하세요.
    </h2>
    <button-basic color="blue" width="300" @click="onMoveLoginPage">로그인 하기</button-basic>
  </div>
</template>

<script>
export default {
  name: 'ResetSuccess',
  props: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onMoveLoginPage() {
      this.$router.push('/login').catch((err) => err);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'ResetSuccess';
</style>
