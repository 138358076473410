var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("유효기간 만료")]),
      _c("h2", { staticClass: "mb-20" }, [
        _vm._v("비밀번호 등록 유효기간이 만료되었습니다."),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "footer-btn-wrapper" },
        [
          _c("input-box", {
            staticClass: "mb-30",
            attrs: {
              type: "text",
              id: "email",
              name: "mail",
              placeholder: "이메일 아이디 입력",
              autocomplete: "off",
            },
            on: { input: _vm.onCheckValidity },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _c(
            "button-basic",
            {
              attrs: { color: "gray-border-1", width: "215" },
              on: { click: _vm.onMoveLoginPage },
            },
            [_vm._v("취소")]
          ),
          _c(
            "button-basic",
            {
              attrs: {
                color: "blue",
                width: "215",
                disabled: !_vm.isValidEmail,
              },
              on: { click: _vm.onSendEmail },
            },
            [_vm._v("등록")]
          ),
        ],
        1
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: { close: _vm.onMoveLoginPage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "mb-10" }, [
      _vm._v(
        " 비밀번호 등록(혹은 재설정)을 원하시면 이메일 아이디를 입력해주세요. "
      ),
      _c("br"),
      _vm._v(" 등록된 이메일 계정으로 비밀번호 재설정 메일이 발송됩니다. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }