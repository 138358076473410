<template>
  <div>
    <h1>유효기간 만료</h1>
    <h2 class="mb-20">비밀번호 등록 유효기간이 만료되었습니다.</h2>
    <h2 class="mb-10">
      비밀번호 등록(혹은 재설정)을 원하시면 이메일 아이디를 입력해주세요.
      <br />
      등록된 이메일 계정으로 비밀번호 재설정 메일이 발송됩니다.
    </h2>
    <div class="footer-btn-wrapper">
      <input-box
        type="text"
        id="email"
        name="mail"
        placeholder="이메일 아이디 입력"
        v-model="email"
        autocomplete="off"
        class="mb-30"
        @input="onCheckValidity"
      />
      <button-basic color="gray-border-1" width="215" @click="onMoveLoginPage">취소</button-basic>
      <button-basic color="blue" width="215" @click="onSendEmail" :disabled="!isValidEmail">등록</button-basic>
    </div>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onMoveLoginPage"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputBox from '@/component/input/InputBox';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import validEmail from '@/asset/js/validEmail';

export default {
  name: 'ExpiryValidity',
  props: [],
  data() {
    return {
      email: '',
      showedPopupName: '',
      popupContentsKey: '',
      isValidEmail: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('users', ['GET_RESET_PASSWORD']),
    onMoveLoginPage() {
      this.$router.push('/').catch((err) => err);
    },
    async onSendEmail() {
      await this.GET_RESET_PASSWORD(this.email);
      this.togglePopup('ConfirmPopup', 'RESET_PASSWORD_VALIDITY');
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onCheckValidity(value) {
      if (validEmail(value)) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },
  },
  components: { InputBox, ConfirmPopup },
};
</script>

<style scoped lang="scss">
@import 'ExpiryValidity';
</style>
