<template>
  <section class="resetPassword-section vh-center">
    <reset v-if="isShowComp === 'reset-pw'" :tokenValue="tokenValue" @changeComponent="onChangeComponent" />
    <reset-success v-else-if="isShowComp === 'reset-pw-success'" @changeComponent="onChangeComponent" />
    <expiry-validity v-else @changeComponent="onChangeComponent" />
  </section>
</template>

<script>
import Reset from '@/view/resetPassword/component/reset/Reset';
import ResetSuccess from '@/view/resetPassword/component/resetSuccess/ResetSuccess';
import ExpiryValidity from '@/view/resetPassword/component/expiryValidity/ExpiryValidity';

export default {
  name: 'ResetPassword',
  props: [],
  data() {
    return {
      isShowComp: 'reset-pw',
      tokenValue: '',
    };
  },
  computed: {},
  created() {
    if (!this.$route.query.value) {
      this.$router.replace('/forbidden').catch((err) => err);
    } else {
      this.tokenValue = this.$route.query.value;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    onChangeComponent(compName) {
      this.isShowComp = compName;
    },
  },
  components: { Reset, ResetSuccess, ExpiryValidity },
};
</script>

<style scoped lang="scss">
@import 'ResetPassword';
</style>
