<template>
  <div>
    <h1>MSF 계정 비밀번호 설정</h1>
    <h2 class="mb-45">사용하고자하는 계정의 비밀번호를 입력하세요.</h2>
    <input-box
      type="password"
      id="password"
      label="비밀번호"
      :maxlength="16"
      v-model="password"
      autocomplete="off"
      tabindex="1"
      :errorMessage="pwErrorMessage"
      :defaultMessage="pwMessage"
      @input="onCheckInputValue"
      is-height-large
    />
    <input-box
      type="password"
      id="re-password"
      label="비밀번호 확인"
      :maxlength="16"
      v-model="re_password"
      autocomplete="off"
      tabindex="2"
      :errorMessage="rePwErrorMessage"
      @keyupenter="requestPassword"
      class="mb-30"
      @input="onCheckInputValue"
      is-height-large
    />
    <button-basic
      color="blue"
      width="100%"
      height="40"
      :loading-dot="isLoading"
      @click="requestPassword"
      :disabled="!password || !re_password"
    >
      등록
    </button-basic>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onMoveLoginPage"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import validPassword from '@/asset/js/validPassword';
import InputBox from '@/component/input/InputBox';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';

export default {
  name: 'Reset',
  props: ['tokenValue'],
  data() {
    return {
      password: '',
      re_password: '',
      pwErrorMessage: '',
      rePwErrorMessage: '',
      pwMessage: '비밀번호는 영문+숫자 조합, 최소 6자 이상 16자 이하로 입력하셔야 합니다.',
      isLoading: false,
      showedPopupName: '',
      popupContentsKey: '',
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('users', ['PUT_RESET_PASSWORD']),
    initErrorMessage() {
      this.pwErrorMessage = '';
      this.rePwErrorMessage = '';
    },
    checkError() {
      this.initErrorMessage();

      if (this.password !== this.re_password) {
        this.rePwErrorMessage = '비밀번호가 일치하지 않습니다.';
        return true;
      }
      if (!validPassword(this.password)) {
        this.pwErrorMessage = '비밀번호는 영문+숫자 조합, 최소 6자 이상 16자 이하로 입력하셔야 합니다.';
        this.rePwErrorMessage = ' ';
        return true;
      }
      return false;
    },
    async requestPassword() {
      if (!this.isLoading && !this.checkError()) {
        try {
          this.isLoading = true;
          const passwordData = {
            token: this.tokenValue,
            changePassword: this.password,
          };
          await this.PUT_RESET_PASSWORD(passwordData);
          this.$emit('changeComponent', 'reset-pw-success');
        } catch (error) {
          if (error.response.status === 400) {
            this.togglePopup('ConfirmPopup', 'REGISTER_DONE_PW');
          } else if (error.response.status === 401) {
            this.$emit('changeComponent', 'expiry_validity');
          } else {
            alert(error);
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    onMoveLoginPage() {
      this.togglePopup();
      this.$router.push('/').catch((err) => err);
    },
    onCheckInputValue(value) {
      if (!value) {
        this.initErrorMessage();
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
  },
  components: { InputBox, ConfirmPopup },
};
</script>
