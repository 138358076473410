var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("MSF 계정 비밀번호 설정")]),
      _c("h2", { staticClass: "mb-45" }, [
        _vm._v("사용하고자하는 계정의 비밀번호를 입력하세요."),
      ]),
      _c("input-box", {
        attrs: {
          type: "password",
          id: "password",
          label: "비밀번호",
          maxlength: 16,
          autocomplete: "off",
          tabindex: "1",
          errorMessage: _vm.pwErrorMessage,
          defaultMessage: _vm.pwMessage,
          "is-height-large": "",
        },
        on: { input: _vm.onCheckInputValue },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("input-box", {
        staticClass: "mb-30",
        attrs: {
          type: "password",
          id: "re-password",
          label: "비밀번호 확인",
          maxlength: 16,
          autocomplete: "off",
          tabindex: "2",
          errorMessage: _vm.rePwErrorMessage,
          "is-height-large": "",
        },
        on: { keyupenter: _vm.requestPassword, input: _vm.onCheckInputValue },
        model: {
          value: _vm.re_password,
          callback: function ($$v) {
            _vm.re_password = $$v
          },
          expression: "re_password",
        },
      }),
      _c(
        "button-basic",
        {
          attrs: {
            color: "blue",
            width: "100%",
            height: "40",
            "loading-dot": _vm.isLoading,
            disabled: !_vm.password || !_vm.re_password,
          },
          on: { click: _vm.requestPassword },
        },
        [_vm._v(" 등록 ")]
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: { close: _vm.onMoveLoginPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }