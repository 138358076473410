var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-success" },
    [
      _c("h1", [_vm._v("비밀번호 설정 완료")]),
      _vm._m(0),
      _c(
        "button-basic",
        {
          attrs: { color: "blue", width: "300" },
          on: { click: _vm.onMoveLoginPage },
        },
        [_vm._v("로그인 하기")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v(" 비밀번호 설정이 완료되었습니다. "),
      _c("br"),
      _vm._v(" 등록하신 비밀번호로 로그인 후 사용하세요. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }