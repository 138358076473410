var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "resetPassword-section vh-center" },
    [
      _vm.isShowComp === "reset-pw"
        ? _c("reset", {
            attrs: { tokenValue: _vm.tokenValue },
            on: { changeComponent: _vm.onChangeComponent },
          })
        : _vm.isShowComp === "reset-pw-success"
        ? _c("reset-success", {
            on: { changeComponent: _vm.onChangeComponent },
          })
        : _c("expiry-validity", {
            on: { changeComponent: _vm.onChangeComponent },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }